import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dea1556a = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _751883aa = () => interopDefault(import('../pages/certifications.vue' /* webpackChunkName: "pages/certifications" */))
const _29e3db41 = () => interopDefault(import('../pages/page.vue' /* webpackChunkName: "pages/page" */))
const _0166be3a = () => interopDefault(import('../pages/resources.vue' /* webpackChunkName: "pages/resources" */))
const _083dab5a = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _1c282742 = () => interopDefault(import('../pages/showcase.vue' /* webpackChunkName: "pages/showcase" */))
const _057c284b = () => interopDefault(import('../pages/specs1.vue' /* webpackChunkName: "pages/specs1" */))
const _058a3fcc = () => interopDefault(import('../pages/specs2.vue' /* webpackChunkName: "pages/specs2" */))
const _75406bb4 = () => interopDefault(import('../pages/showcases/_.vue' /* webpackChunkName: "pages/showcases/_" */))
const _9f319fe0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48b03c1d = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _dea1556a,
    name: "about"
  }, {
    path: "/certifications",
    component: _751883aa,
    name: "certifications"
  }, {
    path: "/page",
    component: _29e3db41,
    name: "page"
  }, {
    path: "/resources",
    component: _0166be3a,
    name: "resources"
  }, {
    path: "/search",
    component: _083dab5a,
    name: "search"
  }, {
    path: "/showcase",
    component: _1c282742,
    name: "showcase"
  }, {
    path: "/specs1",
    component: _057c284b,
    name: "specs1"
  }, {
    path: "/specs2",
    component: _058a3fcc,
    name: "specs2"
  }, {
    path: "/showcases/*",
    component: _75406bb4,
    name: "showcases-all"
  }, {
    path: "/",
    component: _9f319fe0,
    name: "index"
  }, {
    path: "/*",
    component: _48b03c1d,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
