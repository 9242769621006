//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    submitSearch() {
      const input = document.getElementById("searchq");
      const { value } = input;
      this.$router.push({
        path: "/search",
        query: {
          term: value,
        },
      });
    },
  },
};
