import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3700715b&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GovBanner: require('/var/www/cicada-idnr/nuxt/components/GovBanner/GovBanner.vue').default,Search: require('/var/www/cicada-idnr/nuxt/components/Search/Search.vue').default,NavBlock: require('/var/www/cicada-idnr/nuxt/components/NavBlock/NavBlock.vue').default,ShowcaseList: require('/var/www/cicada-idnr/nuxt/components/ShowcaseList/ShowcaseList.vue').default,InfoBlock: require('/var/www/cicada-idnr/nuxt/components/InfoBlock/InfoBlock.vue').default,SocialButton: require('/var/www/cicada-idnr/nuxt/components/SocialButton/SocialButton.vue').default})
