//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GetShowcases from "~/apollo/queries/getShowcases.gql";

export default {
  props: {
    type: {
      type: String,
      default: "regular",
      validator(value) {
        // The value must match one of these strings
        return ["main", "featured", "regular"].includes(value);
      },
    },
    count: {
      type: Number,
      default: 1,
    },
    contentField: {
      type: String,
      validator(value) {
        return ["content", "excerpt"].includes(value);
      },
    },
    link: {
      type: Boolean,
      default: true
    }
  },
  apollo: {
    showcases: {
      query: GetShowcases,
      variables() {
        // return { first: this.$props.count, showcaseType: this.$props.type };
        return { showcaseType: this.$props.type };
      },
      update({showcases}) {
        return showcases?.edges.map((e) => e.node);
      },
    },
  },
};
