//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tag: {
      type: String,
      default: "li",
    },
    size: {
      type: String,
      default: "big",
    },
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    color() {
      return this.$props?.menuItem?.menuItemCustom?.color || "autumn";
    },
    itemPath() {
      return this.$props.menuItem?.path;
    },
    label() {
      return this.$props.menuItem?.label;
    },
  },
};
