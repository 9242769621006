//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from "~/components/Search/Search";
import GetMainMenu from "~/apollo/queries/getMainMenu";
import GetSecondaryMenu from "~/apollo/queries/getSecondaryMenu";
import GetFooterText from "~/apollo/queries/getFooterText";
import SocialButton from "~/components/SocialButton/SocialButton.vue";
import GovBanner from "~/components/GovBanner/GovBanner.vue";

export default {
  components: {
    Search,
    SocialButton,
    GovBanner,
  },
  data() {
    return {
      showMenu: false,
      activePage: "activeTab",
      socialList: [
        {
          label: "Email",
          url: "mailto: cicada-idnr@gmail.com?subject=Message from CICADA",
        },
        {
          label: "Facebook",
          url: "https://www.facebook.com/IllinoisDNR/",
        },
        {
          label: "Twitter",
          url: "https://twitter.com/IllinoisDNR",
        },
        {
          label: "Instagram",
          url: "https://instagram.com/illinoisdnr?igshid=1kg6i3idj9cax",
        },
      ],
    };
  },
  apollo: {
    postBy: {
      query: GetFooterText,
      update(data) {
        //console.log("GetFooterText data",data);
        return data.postBy;
      }
    },
    mainMenu: {
      query: GetMainMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
    secondaryMenu: {
      query: GetSecondaryMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
  },
  computed: {
    footerText() {
      return this.pageBy?.content;
    },
  },
  methods: {
    showNav() {
      // console.log("Ping");
      this.showMenu = this.showMenu === false;
    },
    getClass(x) {
      const activeClass = this.activePage;
      if (this.$route.fullPath.includes(x.toLowerCase())) {
        return activeClass;
      }
    },
  },
};
