//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Example infoItem prop
 * {
 * "label": "Eco-CAT",
 * "url": "https://dnr2.illinois.gov/EcoPublic/",
 * "menuItemCustom": {
 *    "color": "sky",
 *    "gemImage": {
 *      "sourceUrl": https://cicada-idnr.s3.amazoonaws.com/w-content/uploads/2020/Eco-CATLogo.png"
 *     }
 *   }
 * }
 */
export default {
  data() {
    return {

    }
  },
  props: {
    tag: {
      type: String,
      default: "li",
    },
    infoItem: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    label() {
      return this.$props?.infoItem?.node?.label;
    },
    color() {
      return this.$props?.infoItem?.node?.menuItemCustom?.color || "cloud";
    },
    url() {
      return this.$props.infoItem.node?.url;
    },
    sourceUrl() {
      return this.$props.infoItem.node?.menuItemCustom?.gemImage?.sourceUrl;
    },
    altText() {
      return this.$props.infoItem.node.menuItemCustom.gemImage.altText;
    },
  },
};
