/*
  2WAV INC. PROVIDES THIS SOFTWARE TO YOU AS IS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. 

  © 2020 2wav inc. All rights reserved, except as specifically provided by Agreement.
*/

/* eslint-disable no-console */
export default (
  { graphQLErrors, networkError, operation, forward },
  nuxtContext
) => {
  console.log("Global error handler");
  console.log(graphQLErrors, networkError, operation, forward);
};
