//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Example socialItem prop
 * {
 *  "label": "Facebook",
 *  "url": "https://facebook.com/cicada-idnr",
 * }
 */
export default {
  props: {
    tag: {
      type: String,
      default: "li",
    },
    socialItem: {
      type: Object,
      default: () => ({}),
    },
  },
};
