//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCarousel } from "bootstrap-vue";
import Search from "~/components/Search/Search";
import NavBlock from "~/components/NavBlock/NavBlock";
import SocialButton from "~/components/SocialButton/SocialButton.vue";
import InfoBlock from "~/components/InfoBlock/InfoBlock";
import GovBanner from "~/components/GovBanner/GovBanner.vue";
import GetMainMenu from "~/apollo/queries/getMainMenu";
import GetSecondaryMenu from "~/apollo/queries/getSecondaryMenu";
import InfoMenu from "~/apollo/queries/getInfoMenu";
import GetQuote from "~/apollo/queries/getQuote";
import PrimaryShowcase from "~/apollo/queries/primaryShowcase.gql";
import GetFooterText from "~/apollo/queries/getFooterText";
import ShowcaseList from "~/components/ShowcaseList/ShowcaseList.vue";

// New GA integration
// function gtag() {
//   window.dataLayer.push(arguments);
// }
// if (process.client) {
//   window.dataLayer = window.dataLayer || [];
//   gtag("js", new Date());
//   gtag("config", "G-E9PYKW33SP");
//   gtag("send", "pageview");
// }

// Old school GA integration
function gtag() {
  window.dataLayer.push(arguments);
}
if (process.client) {
  window.dataLayer = window.dataLayer || [];
  gtag("js", new Date());
  gtag("config", "G-E9PYKW33SP", { "debug_mode":true });
}

export default {
  components: {
    ShowcaseList,
    Search,
    NavBlock,
    SocialButton,
    InfoBlock,
    BCarousel,
    GovBanner,
  },
  data() {
    return {
      newMenu: [],
      showMenu: false,
      socialList: [
        {
          label: "Email",
          url: "mailto: cicada-idnr@gmail.com?subject=Message from CICADA",
        },
        {
          label: "Facebook",
          url: "https://www.facebook.com/IllinoisDNR/",
        },
        {
          label: "Twitter",
          url: "https://twitter.com/IllinoisDNR",
        },
        {
          label: "Instagram",
          url: "https://instagram.com/illinoisdnr?igshid=1kg6i3idj9cax",
        },
      ],
    };
  },
  computed: {
    updatedMenu() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.newMenu = [];
      for (let i = 0; i < this.secondaryMenu.length; i++) {
        this.newMenu.push(this.secondaryMenu[i]);
      }
      this.newMenu.push({
        databaseId: 0,
        label: "Donate to Conservation",
        menuItemCustom: {
          color: "autumn",
        },
        path: "https://ilconservation.org/Get-Involved/Donate",
      });
      return this.newMenu;
    },
  },
  apollo: {
    postBy: {
      query: GetFooterText,
    },
    post: {
      query: GetQuote,
    },
    mainMenu: {
      query: GetMainMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
    secondaryMenu: {
      query: GetSecondaryMenu,
      update(data) {
        return data.menu?.menuItems?.edges?.map((e) => e.node);
      },
    },
    showcase: {
      query: PrimaryShowcase,
    },
    menu: {
      query: InfoMenu,
    },
  },
  methods: {
    showNav() {
      if (this.showMenu === false) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
  },
};
