//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: "content", // you can set a custom layout for the error page
  props: {
    // eslint-disable-next-line vue/require-default-prop
    error: Object,
  },
};
