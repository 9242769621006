//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showcase: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "regular",
    },
    contentField: {
      type: String,
      validator(value) {
        return ["content", "excerpt"].includes(value);
      },
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    link: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imgUrl() {
      return this.$props.showcase?.featuredImage?.node?.mediaItemUrl;
    },
  },
};
